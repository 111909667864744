import { FacebookIcon, LinkedinIcon, TelegramIcon } from '@/assets';

export const socialNetworks = [
  {
    icon: LinkedinIcon,
    url: 'https://www.linkedin.com/company/warptech-jsc/',
  },
  {
    icon: FacebookIcon,
    url: 'https://www.facebook.com/warppipetrading',
  },
  {
    icon: TelegramIcon,
    url: 'https://t.me/warppipesupport',
  },
  // {
  //   icon: 'twitter.svg',
  //   url: 'https://twitter.com/warppipetrading',
  // },
  // {
  //   icon: 'youtube.svg',
  //   url: 'https://www.youtube.com/@Warppipegame',
  // },
  // {
  //   name: 'Medium',
  //   icon: 'medium.svg',
  //   url: 'https://medium.com/@aimasterbot',
  // },
];
