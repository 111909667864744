import {
  EllipseFooter,
  EllipseIntroduce,
  LogoBinance,
  LogoBitget,
  LogoGuru,
  LogoHSCT,
  LogoOkx,
  LogoSota,
  LogoSui,
  LogoTitan,
  OurMissionIcon,
  OurVision,
} from '@/assets';
import useResponsive from '@/hooks/useResponsive';
import { ArrowRight } from 'iconsax-react';
import Marquee from 'react-fast-marquee';

const Home = () => {
  const isMobile = useResponsive();
  const imageOurPortfolio = [
    LogoTitan,
    LogoGuru,
    LogoHSCT,
    LogoBinance,
    LogoOkx,
    LogoSui,
    LogoBitget,
    LogoSota,
  ];
  return (
    <div className="home-page">
      {isMobile ? (
        <div className="animation-home">
          <h1 className="shorten">Shorten The Way To Success</h1>
          <div className="software-service-mobile">
            <div className="content">Software Service - Development - Investment</div>
          </div>
          <div className="box-center">
            <div className="box">
              <div className="face left"></div>
              <div className="face right"></div>
              <div className="face front"></div>
              <div className="face back"></div>
              <div className="face top"></div>
              <div className="face bottom"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="animation-home">
          <div className="box-center">
            <div className="box">
              <div className="face left"></div>
              <div className="face right"></div>
              <div className="face front"></div>
              <div className="face back"></div>
              <div className="face top"></div>
              <div className="face bottom"></div>
            </div>
          </div>
          <Marquee className="marquee-text">Shorten The Way To Success</Marquee>
          <div className="software-service">Software Service - Development - Investment</div>
        </div>
      )}
      <div className="ellipse-gradient"></div>
      <div className="introduce-warppipe">
        <div className="introduce">
          With nearly 4 years of development experience, we have gathered many excellent individuals
          to create a solid team. Provide services to more than 10 countries around the world,
          especially Korea, Russia and China. With many projects in different fields, such as
          Education, E-Commerce, Finance, Blockchain...
        </div>
        <div className="ellipse">
          <div className="image">
            <img src={EllipseIntroduce} alt="" />
          </div>
        </div>
        <div className="list-service">
          <div className="service">
            <div className="title">Services at warppipe</div>
            <div className="item-service">
              <p className="label">Software service</p>
              <p className="description">
                Warppipe is a leading provider of technology services with youth, enthusiasm,
                commitment to bring the best quality in IT. We deliver sustainable Software
                Development, including Web/App, Blockchain, AI & Machine Learning, ERP with
                cost-effective solutions.
              </p>
              <p className="text-purple">More</p>
            </div>
            <div className="item-service">
              <p className="label">Software Development</p>
              <p className="description">
                Warppipe is a developer and co-founder of technology products, with the mindset of
                optimizing the experience and shortening the road to success. With strengths in
                Blockchain, AI and economics, such products can be mentioned as: Titan Trading
                Platform, Moneybot App, AI Assistant,...
              </p>
              <p className="text-purple">What we do?</p>
            </div>
            <div className="item-service">
              <p className="label">News & Agency</p>
              <p className="description">
                Warppipe is also the unit that provides the latest knowledge and news about
                Blockchain. Provide marketing solutions services. As a unit implementing production,
                creative, marketing communication campaigns.
              </p>
              <p className="text-purple">What we do?</p>
            </div>
            <div className="item-service">
              <p className="label">Investment</p>
              <p className="description">
                As a unit that wishes to contribute to the incubation of young startups. Diversify
                the product ecosystem.
              </p>
              <p className="text-purple">What we do?</p>
            </div>
          </div>
        </div>
      </div>
      <div className="our-vision">
        <div className="content">
          <p className="title">Our Vision</p>
          <div className="description">
            Become a leader, use technology for life and business, and optimize the process of
            generating profits.
          </div>
          <div className="flex-end">
            <div className="image">
              <img src={OurVision} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="our-mission">
        <div className="content">
          <p className="title">Our Mission</p>
          <div className="description">
            Accelerate the mass adoption of emerging technologies through optimizing the experience,
            beyond what was expected and expedient access to global market resources.
          </div>
        </div>
        <div className="icon">
          <img src={OurMissionIcon} alt="" />
        </div>
      </div>
      <div className="ellipse-footer">
        <div className="our-portfolio">
          <div className="content">
            <p className="title">Our Portfolio Companies and Clients</p>
            <div className="list-logo">
              {imageOurPortfolio.map((item, index) => (
                <div key={index} className="item">
                  <img src={item} alt="" />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="join-newsletter">
          <div className="left">
            <p className="title">Join Newsletter</p>
            <p className="description">Subscribe to our free newsletter and follow us</p>
          </div>
          <div className="right">
            <ArrowRight size="168" color="#101828" />
          </div>
        </div>
        <div className="ellipse">
          <img src={EllipseFooter} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
