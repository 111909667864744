import { LogoWarppipe } from '@/assets';
import useResponsive from '@/hooks/useResponsive';
import { HambergerMenu } from 'iconsax-react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const isMobile = useResponsive();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const listContent = [
    {
      name: 'Home',
      path: '/',
    },
    {
      name: 'Expertise & Service',
      path: '/expertise-service',
    },
    {
      name: 'Team',
      path: '/team',
    },
    {
      name: 'News',
      path: 'https://coinmoi.com',
    },
  ];
  return (
    <header className="header-warppipe">
      <Link className="logo-warppipe" to="/">
        <img src={LogoWarppipe} alt="logo" />
      </Link>
      {isMobile ? (
        <div className="menu-mobile">
          <HambergerMenu
            size="24"
            color="#344054"
            onClick={() => setIsPopoverVisible(!isPopoverVisible)}
          />
          {isPopoverVisible && (
            <div className="menu-content-mobile">
              {listContent.map((item, index) => {
                return item.name === 'News' ? (
                  <a href={item.path} target="_blank" rel="noopener noreferrer">
                    {item.name}
                  </a>
                ) : (
                  <Link
                    key={index}
                    to={item.path}
                    className={`${location.pathname === item.path ? 'active' : ''}`}
                    onClick={() => setIsPopoverVisible(false)}
                  >
                    {item.name}
                  </Link>
                );
              })}
              <div className="contact-us-mobile">
                <div className="contact-us-mobile-text">Contact us</div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="content">
            {listContent.map((item, index) => {
              return item.name === 'News' ? (
                <a href={item.path} target="_blank" rel="noopener noreferrer">
                  {item.name}
                </a>
              ) : (
                <Link
                  key={index}
                  to={item.path}
                  className={`${location.pathname === item.path ? 'active' : ''}`}
                >
                  {item.name}
                </Link>
              );
            })}
          </div>
          <div className="contact-us">Contact us</div>
        </>
      )}
    </header>
  );
};

export default Header;
