import { LogoWarppipe } from '@/assets';
import { socialNetworks } from '@/constants/home';

const Footer = () => {
  return (
    <footer className="footer-warppipe">
      <div className="left">
        <div className="logo-warppipe">
          <img src={LogoWarppipe} alt="logo" />
        </div>
        <p className="title">2023 Warppipe Joint Stock Company</p>
      </div>
      <div className="right">
        {socialNetworks.map((item, index) => (
          <a key={index} href={item.url} rel="noopener noreferrer" target="_blank">
            <img src={item.icon} alt="" />
          </a>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
