import { founders, members } from '@/constants/team';

const Team = () => {
  return (
    <div className="team-warppipe">
      <div className="founder">
        <p className="title">Founder</p>
        <div className="list-founder">
          {founders.map((item, index) => (
            <div key={index + 1} className="employ-item">
              <div className="image">
                <img src={`/images/members/founder-${index + 1}.png`} alt="" loading="lazy" />
              </div>
              <div className="info">
                <p className="name">{item.name}</p>
                <p className="position">{item.position}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="member">
        <p className="title">Members</p>
        <div className="list-founder">
          {members.map((item, index) => (
            <div key={index + 1} className="employ-item">
              <div className="image">
                <img src={`/images/members/member-${index + 1}.png`} alt="" loading="lazy" />
              </div>
              <div className="info">
                <p className="name">{item.name}</p>
                <p className="position">{item.position}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
