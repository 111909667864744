import { PublicRoutes } from '@/routes/PublicRoutes';
import { BrowserRouter } from 'react-router-dom';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import './styles/index.scss';

export default function App() {
  return (
    <BrowserRouter>
      <PublicRoutes />

      <ToastContainer
        closeButton={false}
        autoClose={3000}
        transition={Zoom}
        enableMultiContainer
        pauseOnFocusLoss={false}
        position={toast.POSITION.TOP_RIGHT}
        draggable={false}
        limit={3}
        hideProgressBar
      />
    </BrowserRouter>
  );
}
