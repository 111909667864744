import Footer from '@/layouts/Footer';
import Header from '@/layouts/Header';
import React from 'react';
import { Outlet } from 'react-router-dom';

const LayoutMain = () => {
  return (
    <div className="layout-main">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default LayoutMain;
