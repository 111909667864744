export const founders = [
  {
    name: 'Toan Tong',
    position: 'Investor',
  },
  {
    name: 'Hang Mentos',
    position: 'CEO',
  },
  {
    name: 'Henry Pham',
    position: 'CPO',
  },
  {
    name: 'Oliver Green',
    position: 'CMO',
  },
  {
    name: 'Edward Hoang',
    position: 'Tech leader',
  },
  {
    name: 'John Nguyen',
    position: 'AI Team leader',
  },
  {
    name: 'Cuong Tran',
    position: 'Mobile Team leader',
  },
  {
    name: 'Tuan Nguyen',
    position: 'BackEnd Team leader',
  },
];
export const members = [
  {
    name: 'Tuan Truong',
    position: 'Front End Developer',
  },
  {
    name: 'Yen Nguyen',
    position: 'Tester',
  },
  {
    name: 'Huyen',
    position: 'Business Analyst',
  },
  {
    name: 'Loc Tran',
    position: 'Researcher',
  },
  {
    name: 'Nam Than',
    position: 'Developer',
  },
  {
    name: 'Ta Quang Dat',
    position: 'UI/UX Designer',
  },
  {
    name: 'Truong Ho',
    position: 'Marketing',
  },
  {
    name: 'Trang Hoang',
    position: 'Accountant',
  },
  {
    name: 'Hai Nguyen',
    position: 'Graphic Designer',
  },
  {
    name: 'Quan Tran',
    position: 'UI/UX Designer',
  },
  {
    name: 'Hieu Nguyen',
    position: 'Development',
  },
  {
    name: 'Khanh Duy',
    position: 'Development',
  },
  {
    name: 'Tung Nguyen',
    position: 'Tester',
  },
  {
    name: 'Hoang Chu',
    position: 'Development',
  },
  {
    name: 'Le Tuan Anh',
    position: 'Designer',
  },
  {
    name: 'Nguyen Manh Hung',
    position: 'Development',
  },
  {
    name: 'Tran Minh Hieu',
    position: 'Development',
  },
  {
    name: 'Le Le',
    position: 'Tester',
  },
  {
    name: 'Tam',
    position: 'Business Development',
  },
  {
    name: 'Phuong',
    position: 'Content',
  },
  {
    name: 'Quynh Anh',
    position: 'Business Development',
  },
  {
    name: 'Duy Do',
    position: 'Development',
  },
];
