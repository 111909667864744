import { Route, Routes } from 'react-router-dom';

import LayoutMain from '@/layouts/LayoutMain';
import Home from '@/pages/Home';
import Team from '@/pages/Team';

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route element={<LayoutMain />}>
        <Route path="/" element={<Home />} />
        <Route path="/team" element={<Team />} />
      </Route>
    </Routes>
  );
};
